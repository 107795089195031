import React from 'react';
import { css } from '@emotion/core';
import { ReactComponent as NotFoundImage } from '../assets/images/not-found.svg';

const styles = {
  root: css`
    padding: 64px 0;
    text-align: center;
  `,
  image: css`
    margin-bottom: 24px;
  `,
  title: css`
    margin-top: 0;
    margin-bottom: 1.25rem;
    font-size: 28px;
    line-height: 32px;
  `,
};

interface Props {
  title: string;
  text?: React.ReactNode;
  action?: React.ReactNode;
  withImage?: boolean;
}

const EmptyState: React.FC<Props> = ({ title, text, action, withImage }) => (
  <div css={styles.root}>
    {withImage && <NotFoundImage css={styles.image} />}
    <h1 css={styles.title}>{title}</h1>
    {text}
    {action}
  </div>
);

export default EmptyState;
